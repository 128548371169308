import { useEndpoint } from "./common";
import { LineItem } from "../Cart/LineItem";
import { useServerProducts } from "./useProducts";
import { OrderFulfillmentStatus } from "../common/me";
import dayjs from "dayjs";

interface OrderLineItem {
  id: string;
  quantity: number;
  sku: string;
  price: string;
  variantId: string;
}

interface RawOrderType {
  id: string;
  name: string;
  createdAt: string;
  lineItems: OrderLineItem[];
  fulfillmentStatus: OrderFulfillmentStatus;
  cancelledAt: string;
  expectedShippingDate: string;
}

export enum QaplaOrderStatus {
  ATTESA_RITIRO = "ATTESA RITIRO",
  CONSEGNATO = "CONSEGNATO",
  ECCEZIONE = "ECCEZIONE",
  IN_CONSEGNA = "IN_CONSEGNA",
  IN_LAVORAZIONE = "IN LAVORAZIONE",
  IN_TRANSITO = "IN TRANSITO",
  PARTITO = "PARTITO",
  PUNTO_DI_RITIRO = "PUNTO DI RITIRO",
  RIENTRATO = "RIENTRATO",
}

export enum OrderStatus {
  CANCELLED = "Cancelled",
  DELIVERED = "Delivered",
  SHIPPED = "Shipped",
  PROCESSING = "Processing",
}

interface OrderType {
  id: string;
  name: string;
  createdAt: string;
  lineItems: LineItem[];
  fulfillmentStatus: OrderFulfillmentStatus;
  cancelledAt?: string;
  expectedShippingDate: string;
  status: OrderStatus;
  qaplaStatus?: QaplaOrderStatus;
}

export const usePastOrders = () => {
  const { products, productsMap } = useServerProducts();
  const { data: orders, isLoading } = useEndpoint<RawOrderType[]>("/orders");

  if (!products) {
    return { orders: undefined, isLoading: true };
  }

  let mappedOrders: OrderType[] | undefined = undefined;

  const today = dayjs();

  const orderStatus = (rawOrder: RawOrderType): OrderStatus => {
    if (rawOrder.cancelledAt) {
      return OrderStatus.CANCELLED;
    } else if (
      rawOrder.fulfillmentStatus === "fulfilled" &&
      (dayjs(rawOrder.expectedShippingDate) < today ||
        !rawOrder.expectedShippingDate)
    ) {
      return OrderStatus.DELIVERED;
    } else if (rawOrder.fulfillmentStatus === "fulfilled") {
      return OrderStatus.SHIPPED;
    } else {
      return OrderStatus.PROCESSING;
    }
  };

  if (productsMap && orders) {
    mappedOrders = orders?.map((order) => {
      return {
        ...order,
        status: orderStatus(order),
        lineItems: order.lineItems
          .map((li) => {
            const product = productsMap[li.variantId];
            if (!product) {
              return null;
            }
            return {
              product: product,
              quantity: li.quantity,
            };
          })
          .filter((li) => li !== null) as LineItem[],
      };
    });
  }

  return { orders: mappedOrders, isLoading };
};

export type { OrderType };
