import { OrderType } from "../Networking/usePastOrders";
import { Button, Spin } from "antd";
import { useState } from "react";
import useApiClient from "../Networking/useApiClient";
import { TrackingInfo } from "./models";
import dayjs from "dayjs";
import styles from "./styles.module.css";
import { useTranslation } from "react-i18next";
import { ArrowRightOutlined } from "@ant-design/icons";

export const OrderTracking = ({ order }: { order: OrderType }) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [trackingInfo, setTrackingInfo] = useState<
    TrackingInfo | undefined | null
  >();

  const { getTrackingInfo } = useApiClient();

  const today = dayjs();
  const expectedShippingDate = dayjs(order.expectedShippingDate);
  const shouldShowTrackingTool =
    Math.abs(today.diff(expectedShippingDate, "days")) < 7 ||
    today.isBefore(expectedShippingDate);

  if (!shouldShowTrackingTool) {
    return null;
  }

  const loadTrackingInfo = async () => {
    setLoading(true);
    setTrackingInfo(
      await getTrackingInfo({
        orderName: order.name,
      }),
    );
    setLoading(false);
  };

  return (
    <div style={{ padding: 24, paddingBottom: 0 }}>
      {order.qaplaStatus && (
        <div style={{ paddingBottom: 16 }}>
          {t("Stato spedizione")}: {t(order.qaplaStatus)}
        </div>
      )}
      {trackingInfo === undefined && (
        <Button
          className={styles.trackingButton}
          onClick={() => {
            void loadTrackingInfo();
          }}
        >
          {loading ? (
            <Spin
              style={{
                color: "#FFFFFF",
                marginLeft: "30%",
                // width: "100vw",
                // display: "flex",
                // alignItems: "center",
                // justifyContent: "center",
              }}
            />
          ) : (
            <div style={{ width: "100%" }}>
              {t("Traccia il tuo ordine")}
              <ArrowRightOutlined className={styles.buttonIcon} />
            </div>
          )}
        </Button>
      )}

      {trackingInfo === null && (
        <p>{t("Il tuo ordine non è ancora stato spedito")}</p>
      )}
      {trackingInfo && (
        <div className={styles.trackingCard}>
          <div>{t("Stato: " + trackingInfo.status.qaplaStatus)}</div>
          <b
            className={styles.moreInfoBtn}
            onClick={() => {
              window.open(trackingInfo!.url, "_blank");
            }}
          >
            {t("Più informazioni")}
            <img
              src="/icons/external_small_grey.svg"
              alt="open in new tab"
              className={styles.moreInfoIcon}
            />
          </b>
        </div>
      )}
    </div>
  );
};
