import { useMe } from "../AppSetup/MeContext";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useApiClient from "../Networking/useApiClient";
import { useSettings } from "../Networking/useSettings";
import { WaitingListPosition } from "./models";
import { Button, Row, Space, Col } from "antd";
import homeStyles from "../Home/styles.module.css";
import unlimitedStyles from "../Shop/Unlimited/styles.module.css";
import styles from "./styles.module.css";
import { useTranslation } from "react-i18next";
import Logo from "../Home/HomeComponents/Logo";

const WaitingListRecap = () => {
  const { t } = useTranslation();
  const { isWaitingListMember, waitingLists } = useMe();
  const { waitingListId, unlimited } = useSettings();
  const navigate = useNavigate();
  const { getWaitingListPosition } = useApiClient();
  const [waitingListPosition, setwaitingListPosition] = useState<
    WaitingListPosition | undefined | null
  >(undefined);
  const waitingList = waitingLists.filter(
    (waiting_list) => waiting_list.waitingListId === waitingListId,
  )[0];

  useEffect(() => {
    if (!isWaitingListMember) {
      navigate("/");
      return;
    }

    getWaitingListPosition({ waitingListId: waitingList.waitingListId }).then(
      (response) => {
        setwaitingListPosition(response);
      },
    );
  }, [isWaitingListMember, navigate, getWaitingListPosition, waitingList]);

  const renderHome = () => {
    return (
      <Space direction={"vertical"} style={{ width: "100%" }}>
        <Row style={{ marginTop: 16, marginLeft: 16, marginRight: 16 }}>
          <Logo small />
          <div className={homeStyles.headerTitle}>
            <h1>{t("Benvenuto")}</h1>
          </div>
        </Row>
      </Space>
    );
  };

  const renderListInfo = () => {
    return (
      <Row justify="center">
        <Col
          span={8}
          xs={24}
          md={12}
          xl={8}
          style={{ padding: "0 16px 16px 16px" }}
        >
          <div
            className={homeStyles.banner + " " + homeStyles.bannerBox}
            style={{
              marginTop: 16,
              backgroundColor: "var(--light-green)",
              justifyContent: "space-between",
            }}
          >
            <h1
              className={homeStyles.titleSmaller}
              style={{ textAlign: "center", marginBottom: 16 }}
            >
              {t("Il tuo posto in lista d'attesa:")}
            </h1>
            <div className={styles.listPosition}>
              {waitingListPosition?.position}
            </div>
          </div>
          <div className={homeStyles.banner + " " + homeStyles.bannerBox}>
            {t("Quando arriverà il tuo turno:")} <br />
            <div className={styles.listItem}>
              <img
                src={"/icons/check_small_black.svg"}
                alt={"check"}
                style={{ marginTop: 2 }}
              />
              <div>
                {
                  "Ti manderemo una mail e ti verranno automaticamente addebitati"
                }{" "}
                {unlimited?.discountedAmountInCents
                  ? unlimited?.discountedAmountInCents / 100 - 10
                  : 90}
                {"€"}
              </div>
            </div>
            <div className={styles.listItem}>
              <img
                src={"/icons/check_small_black.svg"}
                alt={"check"}
                style={{ marginTop: 2 }}
              />
              <div>
                {t(
                  "Potrai entrare nel sito e iniziare subito a ordinare Humamy 🎉",
                )}
              </div>
            </div>
          </div>
        </Col>
      </Row>
    );
  };

  const renderWarning = () => {
    return (
      <Row justify="center">
        <Col
          span={8}
          xs={24}
          md={12}
          xl={8}
          style={{ padding: "0 16px 0 16px" }}
        >
          <div
            className={homeStyles.banner + " " + unlimitedStyles.bannerFlex}
            style={{
              marginBottom: 16,
              backgroundColor: "var(--light-green)",
              color: "var(--green)",
            }}
          >
            <div style={{ padding: 24 }}>
              <h1
                className={homeStyles.titleSmaller}
                style={{
                  fontSize: 18,
                  display: "flex",
                  gap: 4,
                  marginBottom: 8,
                }}
              >
                <img
                  src={"/icons/alert_small_green.svg"}
                  alt={"alert icon"}
                  style={{ marginTop: 2 }}
                />
                {t("Attenzione")}
              </h1>
              {t(
                "Una box occupa un intero cassetto! Controlla sempre di avere spazio nel freezer prima di ordinare.",
              )}
            </div>
            <div className={unlimitedStyles.bannerPhoto}></div>
          </div>
        </Col>
      </Row>
    );
  };

  const renderHelp = () => {
    return (
      <Row justify="center">
        <Col
          span={8}
          xs={24}
          md={12}
          xl={8}
          style={{ padding: "0 16px 16px 16px" }}
        >
          <div className={homeStyles.banner + " " + homeStyles.bannerBox}>
            <h1
              className={homeStyles.titleSmaller}
              style={{ fontSize: 18, marginBottom: 8 }}
            >
              {t("Dubbi?")}
            </h1>
            {t(
              "Contattaci, e faremo del nostro meglio per rispondere ad ogni domanda.",
            )}
            <br />
            <br />
            <Button
              onClick={() => navigate("/support")}
              className={unlimitedStyles.button}
            >
              {t("Contatta l'assistenza")}
            </Button>
          </div>
        </Col>
      </Row>
    );
  };

  return (
    <>
      {renderHome()}
      {renderListInfo()}
      {renderWarning()}
      {renderHelp()}
    </>
  );
};

export default WaitingListRecap;
