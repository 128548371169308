import { createContext, useContext } from "react";
import { useEndpoint } from "./common";
import useHeaders from "./useHeaders";
import Constants from "../common/constants";

interface FAQItem {
  title: string;
  description: string;
}

interface FAQSection {
  title: string;
  items: FAQItem[];
}

interface BoxConfig {
  name: string;
  items: number;
  price: string;
  nonMembershipPrice?: string;
  comparedPrice?: string;
  originalPrice?: string;
  currentPrice?: string;
  discount?: number;
  isTrial: boolean;
  instalments?: Instalments;
}

interface Period {
  count: number;
  unit: string;
}

interface Subscription {
  sku: string;
  price: number;
  boxSize: number;
  period: Period;
  subscriptionPriceId: string;
}

interface Membership {
  sku: string;
  price: string;
  period: Period;
  freeTrialPeriod?: Period;
}

interface Unlimited {
  newUserPrice: string;
  afterChurnPrice: string;
  membershipId: string;
  discountedAmountInCents?: number;
  standardAmountInCents?: number;
}

interface Instalments {
  immediatePriceInCents: number;
  repeatedPriceInCents: number;
  count: number;
  period: Period;
  membershipSku: string;
}

export enum PurchaseLimitation {
  CannotOrderBeforePreviousBoxDelivery = "CANNOT_ORDER_BEFORE_PREVIOUS_BOX_DELIVERY",
}

interface WaitingListPrice {
  waitingListPriceId: string;
  waitingListAmount: number;
}

interface BackendSettings {
  account: string;
  attributionFormIdentifier?: string;
  membershipInCartByDefault: boolean;
  membershipPercentDiscount: string;
  enableExpertCta: boolean;
  enableTrialBox: boolean;
  boxConfig: BoxConfig[];
  faq: FAQSection[];
  redirect?: string | null;
  trialBoxPriceOverride?: number;
  firstPurchaseDiscountCode?: string;
  firstPurchaseDiscountAmount?: number;
  freemiumMode?: boolean;
  membership?: Membership;
  enableFacebookLogin: boolean;
  enableGoogleLogin: boolean;
  enableTastingBox: boolean;
  subscriptions: Subscription[];
  showSubscriptionOnboarding?: boolean;
  improvedShippingFlow?: boolean;
  productsCollectionId: string;
  country?: string;
  shippingWeekdaysBlocked?: string[];
  supportUrl: string;
  improvedShop: boolean;
  hideOutOfStockProducts?: boolean;
  defaultSubscriptionPeriodInWeeks?: number;
  stripePayment: boolean;
  unlimited?: Unlimited;
  purchaseLimitation?: PurchaseLimitation;
  hideRecapPrices?: boolean;
  stripePaymentConfigurationId?: string;
  hubspotChatEnabledInPages?: string[];
  shopUnderMaintenance?: boolean;
  reviewCooldownInDays?: number;
  unlimitedMinimumPauseDurationInDays?: number;
  waitingListId?: string;
  waitingListPrice?: WaitingListPrice;
}

interface Settings extends BackendSettings {
  subsEnabled: boolean;
  unlimitedEnabled: boolean;
}

const SettingsContext = createContext<Settings | null>(null);

const useSettingsFromBackend = (): Settings | undefined => {
  const { setHeader } = useHeaders();
  const { data: settings } = useEndpoint<BackendSettings>("/settings");
  if (!settings) {
    return undefined;
  }
  setHeader(Constants.SHOPIFY_ACCOUNT, settings.account);
  if (settings.redirect) {
    window.location.replace(settings.redirect);
  }
  return {
    ...settings,
    subsEnabled: !!(
      settings?.subscriptions && settings?.subscriptions.length > 0
    ),
    unlimitedEnabled: !!settings?.unlimited,
  } as Settings;
};

const useSettings = () => {
  const settings = useContext(SettingsContext);
  if (!settings) {
    throw new Error("Invalid Settings context!");
  }
  return settings;
};

export type { Settings, BoxConfig, Subscription, Instalments };

export { useSettingsFromBackend, useSettings, SettingsContext };
