import dayjs from "dayjs";
import { Col, Row } from "antd";
import styles from "./styles.module.css";
import React from "react";
import { OrderStatus, OrderType } from "../Networking/usePastOrders";
import { useTranslation } from "react-i18next";

interface OrderStatusProps {
  order: OrderType;
}

export const StatusPiece = ({
  text,
  active,
}: {
  text: string;
  active: boolean;
}) => {
  const { t } = useTranslation();
  return (
    <Col span={8}>
      <span
        className={
          styles.orderStatus + " " + (active ? styles.active : styles.inactive)
        }
      >
        {t(text)}
        <br />
        <img
          className={styles.orderStatusIcon}
          style={{ width: 15 }}
          src={active ? "/icons/green-v.png" : "/icons/grey-o.png"}
          alt={text}
        />
      </span>
    </Col>
  );
};

export const OrderStatusIcon = ({
  order: { status, expectedShippingDate },
}: OrderStatusProps) => {
  const { t } = useTranslation();

  switch (status) {
    case OrderStatus.CANCELLED:
      return (
        <span style={{ display: "flex", alignItems: "center" }}>
          <img style={{ width: 15 }} src="/icons/red-x.png" alt="canceled" />
          <b style={{ margin: 5 }}>{t("Cancellato")}</b>
        </span>
      );
    case OrderStatus.DELIVERED:
      return (
        <span style={{ display: "flex", alignItems: "center" }}>
          <img style={{ width: 15 }} src="/icons/green-v.png" alt="delivered" />
          <b style={{ margin: 5 }}>
            {expectedShippingDate
              ? t("Consegnato il ") +
                dayjs(expectedShippingDate).format("DD/MM/YY")
              : t("Consegnato")}
          </b>
        </span>
      );
    case OrderStatus.PROCESSING:
      return (
        <Row>
          <StatusPiece text={"Processing"} active={true} />
          <StatusPiece text={"Shipped"} active={false} />
          <StatusPiece text={"Delivered"} active={false} />
        </Row>
      );
    case OrderStatus.SHIPPED:
      return (
        <Row>
          <StatusPiece text={"Processing"} active={true} />
          <StatusPiece text={"Shipped"} active={true} />
          <StatusPiece text={"Delivered"} active={false} />
        </Row>
      );
  }
};
